// const env = process.env.VUE_APP_STAGE || 'development';

let CONFIG = (env) => {
    if (env === 'production') {
        return { 
            API_URL: 'https://cdn.richpanel.com/js/richpanel-root.js',
            BRAND_URL: 'https://ws.richpanel.com/tenant/brand/workspace/',
            CONNECTORS_URL: 'https://ws.richpanel.com/tenant/connector/fetch-connectors-by-brand-id/',
            CONNECTORS_URL_BY_BRAND: 'https://ws.richpanel.com/tenant/connector/fetch-connectors-by-brand-domain/'
        }
    } else {
        return {
            API_URL: 'https://cdn.richpanel.com/js/richpanel-root-dev.js',
            BRAND_URL: 'https://ks-api.richpanel.dev/tenant/brand/workspace/',
            CONNECTORS_URL: 'https://ks-api.richpanel.dev/tenant/connector/fetch-connectors-by-brand-id/',
            CONNECTORS_URL_BY_BRAND: 'https://ks-api.richpanel.dev/tenant/connector/fetch-connectors-by-brand-domain/'
            // CONNECTORS_URL: 'http://localhost:3003/tenant/connector/fetch-connectors-by-brand-id/'
        }
    }

}

export const GET_CONFIG = CONFIG